.modal-overlay-scroll {
  transform: translateZ(0);
  -webkit-overflow-scrolling: touch;
}
.modal-news-container {
  width: 600px;
  max-width: 100%;
  padding: 0;
  border-radius: 3px;
}

.modal-news-close_button {
  background: #FFF;
  border-radius: 100px;
  padding: 5px;
}
.o-modal-news {
  max-height: calc(100vh - 2.4rem);
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  &__close-button {
      
  }
  
  &__banner {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      position: relative;
      height: 180px;
      width: 100%;
      overflow: hidden;
      img {
          position: absolute;
          top: 0;
          width: 100%;
          max-width: 100%;
      }
  }
  &__content {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      padding: 20px 20px 20px;
      @media screen and (min-width: 769px){
          padding: 40px 60px 60px;
      }
  }
  &__title {
      margin-bottom: 20px;
      // word-break: break-all;
  }

  &__text {
      max-width: 100%;
      @include chatbot-mixin-font-size(14);
      line-height: 1.4;
      word-break: keep-all;
      img {
          max-width: 100%;
      }
  }
  &__deal {
      margin-top: 30px;
      padding: 47px;
      border: 2px dashed #E5E5E5;

      @include chatbot-mixin-font-size(16);
  }
  &__deal-highlight {
      color: #EC6B87;
      font-weight: 700;
      text-transform: uppercase;
  }
  &__deal-link {
      margin-top: 30px;
      
      max-width: 320px;
      width: 80%;
      background-color: #CDCDCD;
      
      padding: 15px 35px;

      color: #fff;
      text-align: center;
      text-decoration: none;
      @include chatbot-mixin-font-size(14);
      font-weight: 800;
      text-transform: uppercase;

      border-radius: 3px;

  }
}

.modal-container-partner {
  // width: 90vw;
  // height: 90vh;
  max-height: calc(100vh - 2.4rem);
  max-width: 100%;
  width: 600px;
  padding: 0;

  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 3px;
}

.modal-gen {
  padding: 60px;
  max-width: 460px;
  border-radius: 3px;
  &-title {
      margin-bottom : 50px;

      text-align: center;
      color: #ec6b87;
      @include chatbot-mixin-font-size(14);
      font-weight: 600;
      font-family: "Quicksand";
      letter-spacing: 0.7px;
      text-transform: uppercase;
  }
  &-paragraph {
      
      
      @include chatbot-mixin-font-size(13);
      color: #686868;
      font-family: "Quicksand";
      line-height: 1.53;

      &:not(:last-child) {
          margin-bottom : 20px;
      }

  }

  &-button{
      margin-top : 50px;
  }
  &-close_button {
      display: none;
  }
}

.modal-disconnect {
  padding: 60px;
  max-width: 460px;
  border-radius: 3px;
  -webkit-tap-highlight-color: transparent;
  &-title {
      margin-bottom : 50px;

      text-align: center;
      color: #ec6b87;
      @include chatbot-mixin-font-size(14);
      font-weight: 600;
      font-family: "Quicksand";
      letter-spacing: 0.7px;
      text-transform: uppercase;
  }
  &-paragraph {
      
      
      @include chatbot-mixin-font-size(13);
      color: #686868;
      font-family: "Quicksand";
      line-height: 1.53;

      &:not(:last-child) {
          margin-bottom : 20px;
      }
      &.centered {
          text-align: center;
      }

      &-notes {
          @include chatbot-mixin-font-size($chatbot-font-small);
          font-style: italic;
      }

  }

  &-button{
      margin-top : 50px;
      text-decoration: none;
  }
  &-close_button {
      display: none;
  }
  &-link {
      color: blue;
      text-decoration: underline;
      cursor: pointer;
  }
  &-loader-container {
      display: flex;
      justify-content: center;
  }
}

.modal-form-container {
  font-size: 14px;
  max-width: 520px;
  width: 90%;
  padding: 50px 20px;
  @media screen and (min-width: 769px){
      padding: 50px 70px;
  }
  border-radius: 3px;
  min-height: 95vh;
  max-height: 95vh;
  overflow: auto;
  transition: height ease-in 1s;
  box-sizing: border-box;

  label {
    color: #838383;
  }

  span.required {
    color: red;
  }
  
  .bot {
      position: relative;
      width: 120px;
      height: 120px;
      margin: 0 auto 15px;

      border-radius: 100%;
      background-color: #fff;
      // background: linear-gradient(to top left, #7094E5, #463E96);
      box-shadow: 0 0 0 rgba(250, 250, 0, 0.8); 
      // overflow: hidden;
      svg {
          position: relative;
          z-index: 5;
      }
      .inside-bot {
          width: 120px;
          height: 120px;
          overflow: hidden;
          border-radius: 50%;
          svg {
              max-width: 100%;
              height: auto;
          }
      }

  }

  .chatbot-wizard{
    & > div{
      overflow: hidden;
    }
  }

  .form-group {
      &:not(:last-child){
          margin-bottom: 20px;
      }
      &.field-object {
          // border: 2px solid red;
          .form-group.field-string {
              
              .chatbot-a-title-section-form {
                  
                  padding-top: 10px;
                  .chatbot-a-title-section {
                      // border-top: 1px solid #e2e2e2;
                      padding-top: 30px;
                      margin-bottom: 30px;
                  }
              }

          }

      }

  }

  label.control-label {
      display: inline-block;
      margin-bottom: 7px;
  }
  .text-danger {
      color: red;
      padding : 7px 0;
  }

  #root_anonymous {
      flex-direction: column;
  }

  #root_anonymous .radio {
      margin-top: 10px;
  }

  .field-radio-group {
      display: flex;
      flex-wrap: wrap;
      .radio {
          flex-basis: 50%;
          & > label {
              & > span {
                  display: flex;
                  align-items: center;
                  input[type="radio"]{
                      min-width: 10px;
                      flex: 1;
                  }
                  & > span {
                      flex: 6;
                  }
              }
          }
      }
  }
  // Search Icon Autocomplete google maps
  textarea {
      width: 100%;
      max-width: 100%;
      background-color: #f4f4f4;
      // border: none;
      font-family: "Quicksand";
      border: 1px solid #f4f4f4;
      border-radius: 5px;
      font-family: "Quicksand";
      padding: 15px;
      box-sizing: border-box;
      color: #5A5A5A;
      font-size: 0.9rem;
  }
  .rdtPicker {
      .rdtDays {
          thead {
              font-size: 13px;
              .rdtSwitch {
                  color: #DA552F;
                  vertical-align: middle;
                  font-weight: 500;
                  font-size: 13px;
                  &::first-letter {
                      text-transform: uppercase;
                  }
              }
              .dow {
                  text-transform: uppercase;
              }
          }
      }
      .rdtDay {
          vertical-align: middle;
          @include chatbot-mixin-font-size($chatbot-font-small);
          &.rdtActive {
              background-color: #48CFAD;
              font-weight: 700;
              border-radius: 3px;
          }
          &.rdtToday {
              &:before {
                  background-color: #F3F3F3;
              }
          }
      }
      .rdtTimeToggle {
          vertical-align: middle;
      }
      .rdtTime {
          .rdtSwitch {
              vertical-align: middle;
              font-size: 13px;
          }
      }
      .rdtMonth {
          vertical-align: middle;
          font-size: 13px;
      }
  }
  .chatbot-m-custom-search-location__icon {
      transform: translateY(-50%);
  }

  .modal-form-controls {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
  }

  .modal-form-controls-centered {
      display: flex;
      justify-content: center;
  }

  .chatbot-a-button {
      width: auto;
  }
  input {
    width: 100%;
    height: auto;
    background-color: #f4f4f4;
    border-radius: 5px;
    border: none;
    padding: 15px;
    font-size: 0.9rem;
    color: #5A5A5A;
    box-sizing: border-box;
    box-shadow: none;
    font-family: "Quicksand";
    &::placeholder {
      font-size: 0.9rem;
      color: #5A5A5A;
    }
  }
}

.modal-formFeedback-container {
  display: flex;
  flex-direction: column;
  padding: 40px 80px;
  border-radius: 10px;
  max-width: 580px;
  max-height: 586px;
  transition: height ease-in 1s;
  justify-content: center;
  align-content: center;

  .modal-formFeedback-header {
      color: #5A5A5A;
      font-size: 16px;
      text-align: center;
      font-weight: 500;
      padding: 10px 30px;
      margin-bottom: 30px;
  }


  .modal-formFeedback-btns {
      display: flex;
      justify-content: space-between;
      padding: 5px 10px;
      
      .modal-formFeedback-btn-cancel {
          width: 100%;
          background-color: #EDEDED;
          margin-right: 15px;
          color: #5A5A5A;
          font-size: 14px;
      
      }

      .modal-formFeedback-btn-send {
          width: 100%;
          font-size: 14px;
          background-color: #357ED3;
          color: white;
      }
  }

  input  {
      width: 100%;
      max-width: 100%;
      background-color: #f4f4f4;
      border: none;
      font-family: "Quicksand";
      font-size: 14px;
      padding: 14px 20px;
  }

  ::placeholder {
      font-weight: 400;
  }

  .chatbot-a-block-note {
      line-height: 1.6rem;
  }

  textarea {
      width: 100%;
      max-width: 100%;
      background-color: #f4f4f4;
      border: none;
      font-family: "Quicksand";
      font-size: 14px;
      padding: 14px 20px;
  }

}

.modal-formCalendar-container {
  max-width: 520px;
  width: 90%;
  padding: 0;
  border-radius: 3px;
  // // height: 90vh;
  // min-height: 95vh;
  // // height: auto;
  // max-height: 90vh;
  transition: height ease-in 1s;

  .modal-formCalendar-header {
      padding: 22px 30px;
      border-bottom: 1px solid rgba(#000, .1);
  }

  form {
      padding: 50px 30px;
      padding-top: 20px;
      .chatbot-a-button {
          width: 100%;
      }
  }

  &body {
      padding: 50px 30px;
      padding-top: 20px;
  }
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  .modal-container-close_button {
      width: 20px;
      height: 20px;
  }

  .title-formCalendar {
      font-size: 16px;
      font-weight: 500;
      font-family: "Quicksand";
  }

  .form-group {
      &:not(:last-child){
          margin-bottom: 20px;
      }
  }

  label.control-label {
      display: inline-block;
      margin-bottom: 7px;
  }
  .text-danger {
      color: red;
      padding : 7px 0;
  }

  .field-radio-group {
      display: flex;
      .radio {
          flex: 1;
          & > label {
              & > span {
                  display: flex;
                  align-items: center;
                  input[type="radio"]{
                      min-width: 10px;
                      flex: 1;
                  }
                  & > span {
                      flex: 6;
                  }
              }
          }
      }
  }
  // Search Icon Autocomplete google maps
  textarea {
      width: 100%;
      max-width: 100%;
      background-color: #f4f4f4;
      border: none;
      font-family: "Quicksand";
      font-size: 13px;
      padding: 14px 20px;
  }
  .rdtPicker {
      .rdtDays {
          thead {
              font-size: 13px;
              .rdtSwitch {
                  color: #DA552F;
                  vertical-align: middle;
                  font-weight: 500;
                  font-size: 13px;
                  &::first-letter {
                      text-transform: uppercase;
                  }
              }
              .dow {
                  text-transform: uppercase;
              }
          }
      }
      .rdtDay {
          vertical-align: middle;
          @include chatbot-mixin-font-size($chatbot-font-small);
          &.rdtActive {
              background-color: #48CFAD;
              font-weight: 700;
              border-radius: 3px;
          }
          &.rdtToday {
              &:before {
                  background-color: #F3F3F3;
              }
          }
      }
      .rdtTimeToggle {
          vertical-align: middle;
      }
      .rdtTime {
          .rdtSwitch {
              vertical-align: middle;
              font-size: 13px;
          }
      }
      .rdtMonth {
          vertical-align: middle;
          font-size: 13px;
      }
  }
  .chatbot-m-custom-search-location__icon {
      transform: translateY(-50%);
  }

  .modal-form-controls {
      display: flex;
      justify-content: space-between;
  }

  .modal-form-controls-centered {
      display: flex;
      justify-content: center;
  }

  .chatbot-a-button {
      width: auto;
  }
}

.modal-no-overlay {
  background: transparent;
}

.modal-overlay { 
  padding: 0;
}

.modal-desc-container {
  max-width: 520px;
  
  @media screen and (min-width: 769px) {
      width: 90%;
  }
  padding: 0;
  border-radius: 3px;
  // // height: 90vh;
  // min-height: 95vh;
  // // height: auto;
  // max-height: 90vh;
  transition: height ease-in 1s;

  .modal-desc-header {
      padding: 22px 30px;
      border-bottom: 1px solid rgba(#000, .1);
  }

  .modal-attent {
      padding: 22px 30px;
      border-bottom: 1px solid rgba(#000, .1);
      text-align: center;

      &__header {
          padding: 20px;
          font-size: 18px;
          font-weight: bold;
      }

      &__body {
          display: flex;
          flex-direction: column;
          padding: 10px 20px;

          &-reponses {
              display: flex;
              flex-direction: column;
              font-size: 14px;
              
              &-item {
                  margin-bottom: 25px;

                  &__header {
                      display: flex;
                      justify-content: space-between;
                      margin-bottom: 15px;

                      &-old {
                          display: flex;
                          justify-content: space-between;
                          margin-bottom: 15px;
  
                          & > span {
                              background-color: black;
                              border-top-left-radius: 50px;
                              border-top-right-radius: 0px;
                              border-bottom-right-radius: 50px;
                              border-bottom-left-radius: 50px;
      
                              padding: 7px 10px;
  
                              color: white;
                              font-weight: bold;
                          }
  
                          & > p {
                              padding: 7px 10px;
                              margin-left: 10px;
                          }
                      }

                      & > span {
                          background-color: #4C8FE1;
                          border-top-left-radius: 50px;
                          border-top-right-radius: 0px;
                          border-bottom-right-radius: 50px;
                          border-bottom-left-radius: 50px;
  
                          padding: 7px 10px;

                          color: white;
                          font-weight: bold;
                      }

                      & > p {
                          padding: 7px 10px;
                          margin-left: 10px;
                      }
                  }

                  &__description {
                      display: flex;
                      flex-wrap: wrap;
                      padding: 0px 10px;
                      line-height: 20px;
                  }
              }
          }
      }
  }


  form {
      padding: 50px 30px;
      padding-top: 20px;
      .chatbot-a-button {
          width: 100%;
      }
  }

  .modal-desc-body {
      padding: 50px 30px;
      padding-top: 20px;
      font-size: 12px;
      font-family: "Quicksand";
      article {
          font-size: 14px;
          color: #686868;
          h2 {
              font-size: 16px;
              margin-bottom: 15px;
              font-weight: 400;
          }
          h3 {
              &:last-child {
                  margin-bottom: 5px;
              }
          }
          p {
              &:not(:last-child) {
                  margin-bottom: 15px;
              }
          }
      }
  }
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  .modal-container-close_button {
      width: 20px;
      height: 20px;
  }

  .title-desc-modal {
      @include chatbot-mixin-font-size($chatbot-font-medium);
      font-weight: bold;
      font-family: "Quicksand";
  }


  .modal-form-controls {
      display: flex;
      justify-content: space-between;
  }

  .modal-form-controls-centered {
      display: flex;
      justify-content: center;
  }

  .chatbot-a-button {
      width: auto;
  }
}

.modal-cyconia-title{
  color: #838383;
}
.modal-cyconia-desc {
  font-size: 14px !important;
  font-family: "Quicksand";
  color: #838383;
  line-height: 1.3;
  ul {
      li {
          position: relative;
          margin-left: 10px;
          padding-left: 5px;
          &:first-letter {
              text-transform: uppercase;
          }
          &:before {
              position: absolute;
              left: -5px;
              content: "-";
              display: inline-block;
          }
          &:not(:last-child){
              margin-bottom: 5px;
          }
      }
  }
  strong {
      font-weight: 700;
  }
}

.modal-request{
  max-width: 660px;
  width: 100%;
  padding: 0;
  border-radius: 6px;
  color: #5a5a5a;

  &-loader-container {
      display:flex;
      justify-content:center;
      padding: 30px;
  }
  &-title {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      @include chatbot-mixin-font-size(30);
      background-color: #E6F2FF;
      padding: 30px 30px 20px;
      font-weight: 600;
  }
  &-type {
      background-color: #E6F2FF;
      padding: 0 30px 30px;
      @include chatbot-mixin-font-size(16);
      font-weight: 600;
  }
  &-date {
      @include chatbot-mixin-font-size(14);
      font-weight: 600;
      color: #AAAAAA;
  }
  &-info {
      padding: 30px 30px;
      background-color: #EEF6FF;
      @include chatbot-mixin-font-size(12);
      &-item {
          display: inline-flex;
          align-items: center;
          svg {
              margin-right: 13px;
          }
      }
  }
  &-terms {
      padding: 30px 30px 0;
      &-title {
          font-weight: 600;
          margin-bottom: 15px;
          @include chatbot-mixin-font-size(20);
      }
      &-content {
          @include chatbot-mixin-font-size(14);
          margin-bottom: 45px;
      }
  }
  &-reason {
      padding: 15px 30px 30px;
      &-title {
          font-weight: 600;
          margin-bottom: 15px;
          @include chatbot-mixin-font-size(20);
      }
      &-input {
          width: 100%;
          background-color: #F1F1F1;
          border: none;
          border-radius: 6px;
          padding: 16px;
          min-height: 100px;
          font-family: "Quicksand";
          @include chatbot-mixin-font-size(14);
      }
      &-controls {
          display: flex;
          justify-content: space-between;
          margin-top: 30px;

          .chatbot-a-button {
              display: inline-flex;
              width: auto;
          }
      }
  }
}



.modal-confirm-train {
  padding: 60px;
  max-width: 460px;
  border-radius: 3px;
  -webkit-tap-highlight-color: transparent;

  &-body {
      display: flex;
      flex-direction: column;

      & > h4 {
          font-size: 14px;
          line-height: 20px;
          font-weight: bold;
      }

      & > span {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          line-height: 20px;
          margin-top: 10px;
          margin-bottom: 10px;
          text-align: center;
      }

      & > p {
          font-size: 14px;
          line-height: 20px;            
      }
  }


  &-title {
      margin-bottom : 50px;
      text-align: center;
      color: #ec6b87;
      @include chatbot-mixin-font-size(14);
      font-weight: 600;
      font-family: "Quicksand";
      letter-spacing: 0.7px;
      // text-transform: uppercase;
  }

  &-button{
      margin-top : 50px;
      text-decoration: none;
  }
  &-close_button {
      display: none;
  }
  
  &-link {
      color: blue;
      text-decoration: underline;
      cursor: pointer;
  }
  &-loader-container {
      display: flex;
      justify-content: center;
  }
}

.modal-loader {
  text-align: center;
  margin-top: 100px;
}

.chatbot-m-recap-block {
  margin: 20px 0px;
}

.chatbot-m-card {
  position: relative;
  width: auto;
  background-color: #fff;
  box-shadow: 0 1px 8px 0 rgba($color: #000000, $alpha: 0.1);
  margin-bottom : 40px;
  padding: 20px;
  border-radius: 3px;
  line-height: 22px;
  font-family: "Quicksand";
  color: #838383;
  text-align: center;
  font-size: 0.95rem;
  &::before{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.5em;
    top: 0;
    left: calc(50% + 10px);
    box-sizing: border-box;
    
    border: 3px solid black;
    border-color: transparent transparent #fff #fff;
    
    transform-origin: 0 0;
    transform: rotate(135deg);
    
    box-shadow: -1px 1px 8px 0 rgba(0, 0, 0, 0.05);
  }
  p {
    &:not(:last-child){
      margin-bottom: 20px;
    }
  }
  em {
    font-weight: 700;
  }
  strong {
    font-weight: 700;
  }
}


.chatbot-custom-datepickertime{
  &__container{
      display: flex;
  }

  &__date{
      width: 100%;
      div {
          width: 100%;
      }
  }

  &__time{
      // .ant-time-picker-input {
      //     height: 40px;
      //     padding         : 14px 20px;
      //     border          : none;
      //     background-color: #F4F4F4;
      //     color           : #838383;
      //     border-radius: 3px;
      //     font-size: 10px;
      //     &::placeholder {
      //         color           : #838383;
      //     }
      // }
      .ant-time-picker-icon {
          margin-top: 0;
          transform: translateY(-50%);
      }
  }
}

.chatbot-m-custom-search-location {
  position: relative;
  &__input {
    border-radius: 20px;
    box-shadow: 0 1px 8px 0 rgba($color: #000000, $alpha: 0.1);
    color: #838383;
    @include chatbot-mixin-font-size(12);

    margin-bottom: 15px;
    &:not(:placeholder-shown) + .chatbot-m-custom-search-location__icon {
      opacity: 0;
    }
    &::placeholder {
      font-style: italic;
      font-weight: normal;
      @include chatbot-mixin-font-size(12);
    }
  }
  &__icon {
    position: absolute;
    transition: opacity ease-in .3s;
    top: calc(50% - 5px);
    right: 20px;
    transform: translateY(-50%);

  }
  &__input ~ div {
    div {
      font-size: 14px;
    }
    
    & > div {
      font-size: 14px;
      padding: 15px;
      span {
        font-size: 14px;
        display: inline;
      }
    }
    
  } 
  
}

.chatbot-input-group {
  position: relative;
  margin-bottom: 15px;
  label {
      margin-bottom: 10px;
      display: block;
      .small {
          font-size: 11px;
          text-transform: none;
      }
  }
  &.white {
      label {
          color: #FFF;
      }
  }

  .chatbot-errorMsg {
      position: absolute;
      padding: 3px 7px;
  }

  &__input-wrapper {
      position: relative;

      svg {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
      }
  }
  input.with-icon {
      padding-right: 45px;
  }

  &.dual-theme {
      label {
          @include chatbot-mixin-font-size($chatbot-font-small);
      }

      input {
          height: 60px;
          @include chatbot-mixin-font-size($chatbot-font-regular);
          font-family: "Quicksand";
          color: #5A5A5A !important;
      }
      @media screen and (max-width: 769px){
          label {
              color: #fff;
          }

      }
  }
  &.centered {
      label {
          text-align: center;
      }
  }
}

.chatbot-custom-toast {
  position: relative;
  background-color: #fff;
  color: #838383;
  border-radius: 3px;
  &-success{
      &:before {
          content: "";
          position: absolute;
          height: calc(16px + 100%);
          width: 8px;
          left: 0;
          top: 0;
          background-color: $chabot-green;
      } 
  }
  &-error{
      &:before {
          content: "";
          position: absolute;
          height: calc(16px + 100%);
          width: 8px;
          left: 0;
          top: 0;
          background-color: red;
      } 
  }

  &__body{
      padding-left: 15px;
      font-family: "Quicksand";
      @include chatbot-mixin-font-size($chatbot-font-small);

      &-success {}
  }
  &__progress {
      background-color: #EC6B87;
  }

  &-container {

      &__success{
      }
  }
}

.chatbot-a-link_text {
  @include chatbot-mixin-font-size($chatbot-font-small);
  color: #5A5A5A;
  font-style: italic;
  text-decoration: underline;
  font-family: "Quicksand";
  font-weight: 500;
  @media screen and (min-width: 769px){
    color: $chabot-gray
  }
  &.white {
    color: #fff;
  }
  &:hover {
    color: darken($chabot-gray, 20);
  }
}

.chatbot-lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid #39536A;
    border-radius: 50%;
    animation: chatbot-lds-ring-anim 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #39536A transparent transparent transparent;
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes chatbot-lds-ring-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.chatbot-errorMsg {
  color: red;
  opacity: 0;
  transition: all ease-in-out .3s;
  padding : 15px 7px;
  display: block;
  text-align: center;
  @include chatbot-mixin-font-size($chatbot-font-small);
  &__active{
      opacity: 1;
  }
}

.chatbot-a-block-note {
  margin-bottom: 15px;
}

.chatbot-service-request-form-note {
  font-size: 13px;
  padding-left: 40px;
}

.chatbot-sc-message--text {
  position: relative;
  font-size: 0.8rem;
  width: auto;
  .btn {
    color: #FFFFFF;
    position: relative;
    margin: 5px 0px;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.75;
    background-color: #357ED3;
    width: 100%;
    padding: 10px 10px;
    transition: all ease-in .15s;
    border: transparent;
    border-radius: 5px;
    background-image: none;
    text-transform: none;
    cursor: pointer;
    &:hover {
      background-color: #155EB3;
    }
  }
  .bot_reply_feedback {
    width: auto;
    max-width: 90%;
    margin-top: -0.5%;
    position: absolute;
    right: 0px;
    display: none;
    white-space: nowrap;
    .btn_positive_feedback,
    .btn_negative_feedback,
    .btn_edit_msg {
      box-sizing: border-box;
      width: 40px;
      transition: all ease-in .15s;
      touch-action: manipulation;
      cursor: pointer;
      border: 2px solid #EEEEEE;
      padding: 3px;
      padding-top: 6px;
      background-color: #FFFFFF;
      font-size: 1.1em;
      border-radius: 30px;
      border-bottom-left-radius: 5px;
      margin-left: 10px;
      text-align: center;
      &:hover {
        background-color: #40B176;
      }
    }
    .btn_negative_feedback {
      &:hover {
        background-color: #EC6B87;
      }
    }
    .btn_edit_msg {
      width: 80px;
      &:hover {
        background-color: #357ED3;
      }
    }
  }
}