.chatbot-a-button {
    outline: none;
    cursor: pointer;
    display      : block;
    width        : 100%;
    padding      : 14px;
    text-align   : center;
    border-radius: 3px; // variable
    font-weight  : 700;
    border: none;
    font-family: "Quicksand";
    transition: all .5s ease-in-out;
    @include chatbot-mixin-font-size($chatbot-font-small);
    text-decoration: none;
    &.chatbot-a-button-darkblue {
        background-color: #576A90;
        color           : $chabot-white;
        text-transform  : uppercase;
        &:hover {
            background-color: darken(#576A90, 8%);
            color: darken($chabot-white, 5%);
        }
    }
    &.chatbot-a-button-green {
        background-color: #76C69F;
        color           : $chabot-white;
        text-transform  : uppercase;
        &:hover {
            background-color: darken(#76C69F, 8%);
            color: darken($chabot-white, 5%);
        }
    }
    &.chatbot-a-button-gray {
        background-color: #DDDDDD;
        color           : $chabot-white;
        margin-right: 10px;
        text-transform  : uppercase;
        &:hover {
            background-color: darken(#DDDDDD, 8%);
            color: darken($chabot-white, 5%);
        }
    }
    &.chatbot-a-button-dark {
        background-color: rgba(#000, 0.2);
        color           : $chabot-white;
        text-transform  : uppercase;
    }
    &.chatbot-a-button-red {
        background-color: #F97A7A;
        color           : $chabot-white;
        text-transform  : uppercase;
    }
    &.chatbot-a-button-pink {
        background-color: #EC6B87;
        color           : $chabot-white;
        text-transform  : uppercase;
        &:hover {
            background-color: darken(#EC6B87, 8%);
            color: darken($chabot-white, 5%);
        }
    }
    &.chatbot-a-button-blue {
        background-color: #4A90E2;
        color           : $chabot-white;
        text-transform  : uppercase;
        &:hover {
            background-color: darken(#4A90E2, 8%);
            color: darken($chabot-white, 5%);
        }
    }
    &.chatbot-a-button-purple {
        background-color: #463E96;
        color: #FFF;
        text-transform: uppercase;
        &:hover {
            background-color: darken(#463E96, 8%);
            color: darken($chabot-white, 5%);
        }
    }
    &.disable{
        opacity: .7;
        cursor: not-allowed;
    }

    &-big {
        border-radius: 6px;
        height: 60px;
        @include chatbot-mixin-font-size($chatbot-font-regular);
    }
}

.chatbot-btn-icon{
    background-color: transparent;
    border: none;
    outline: none;
}

.chat-bot-a-title-h3-section {
  @include chatbot-mixin-font-size(14);
  color: $chabot-blue;
  font-weight: $chatbot-font-medium;
  text-transform: uppercase;
  &-grey {
      color: #797979;
  } 
  &-white {
      color: white;
  }
}

.chatbot-a-title-section {
  @include chatbot-mixin-font-size(16);
  color: #5A5A5A;
  font-weight: 500;
  // text-transform: uppercase;
  margin-bottom: 20px;
  &--withsub {
      margin-bottom : 4px;
  }
}

.chatbot-a-title-recap {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: #463E96;
  margin-bottom: 15px;
}