// ////////////////////
// COLOURS 
// ////////////////////

$chabot-white   : #fff;

$chabot-gray: #B7B7B7;

$gray-blue: #576A90;

$chabot-blue : #39536A;

$chabot-green: #00B16A;

// ////////////////////
// FONT SIZE; 
// ////////////////////

$chatbot-font-small : 8;
$chatbot-font-regular : 10;
$chatbot-font-medium : 14;